import "./Landing.css";
import "../../common/skeleton.css";

import avatar from "../../resources/images/profile_500.png";

import discord from "../../resources/icons/discord.svg";
import email from "../../resources/icons/email.svg";
import github from "../../resources/icons/github.svg";
import instagram from "../../resources/icons/instagram.svg";
import linkedin from "../../resources/icons/linkedin.svg";
import youtube from "../../resources/icons/youtube.svg";
import globe from "../../resources/icons/globe.svg";
import { Main, MainContent } from "../../common/elements";

import empty from "../../resources/feed/empty.svg";
import resume from "../../resources/feed/resume.png";
import atlas from "../../resources/feed/Dark_AP.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Landing() {
  const navigate = useNavigate();

  const [lang, _setLang] = useState("default");

  const [name, setName] = useState("═══════");
  const [bio, setBio] = useState("═══════ ═══════");
  const [services, setServices] = useState("═══════ | ═══════ | ═══════");

  const setLanguage = (newLang: string) => {
    if (newLang === "ko") {
      _setLang("ko");

      setName("성민준");
      setBio("현대예술인");
      setServices("웹 디자인 | 영업 및 마케팅 | 브랜딩");
    } else {
      _setLang("en");

      setName("Min Jun Sung");
      setBio("Contemporary Artist");
      setServices("Web Design | Sales & Marketing | Branding");
    }
  };

  // Set language based on browser settings
  useEffect(() => {
    const detectAndSetLanguage = async () => {
      if (navigator.language.startsWith("ko")) {
        setLanguage("ko");
      } else {
        setLanguage("en");
      }
    };

    detectAndSetLanguage();
  }, []);

  return (
    <Main>
      {/* Avatar */}
      <MainContent>
        <div className="Landing-avatar">
          <img src={avatar} alt="Profile of Min Jun Sung"></img>
        </div>
      </MainContent>

      {/* Bio */}
      <MainContent>
        <div className="Landing-bio">
          <h1 className="page-title">{name}</h1>
          <p className="page-subtitle">{bio}</p>

          <br />

          <p className="page-text">{services}</p>
        </div>
      </MainContent>

      {/* Icons */}
      <MainContent>
        <div className="Landing-social-icons">
          <SocialIcon href="https://instagram.com/themrsung/" icon={instagram} alt="Link to Instagram" />
          <SocialIcon href="https://linkedin.com/in/themrsung/" icon={linkedin} alt="Link to LinkedIn" />
          {/* <SocialIcon href="https://youtube.com/@themrsung/" icon={youtube} alt="Link to YouTube" /> */}
          <SocialIcon href="https://github.com/themrsung/" icon={github} alt="Link to GitHub" />
          <SocialIcon href="https://discord.gg/FTM9g5BfgC" icon={discord} alt="Link to Discord" />
          <SocialIcon href="mailto:themrsung@gmail.com" icon={email} alt="Link to send Email" />

          <div className="Landing-social-spacer"></div>

          <SocialClickIcon
            onClick={() => {
              setLanguage(lang === "ko" ? "en" : "ko");
            }}
            icon={globe}
            alt="Change Language"
          />
        </div>
      </MainContent>

      {/* Feed */}
      <MainContent>
        <div className="Landing-feed">
          <FeedElement
            onClick={() => {
              navigate("/resume");
            }}
          >
            <img src={resume} alt="Resume"></img>
          </FeedElement>
          <FeedElement
            onClick={() => {
              navigate("/atlas");
            }}
          >
            <img src={atlas} alt="Atlas Partners"></img>
          </FeedElement>
          <FeedElement>
            <img src={empty} alt="Empty"></img>
          </FeedElement>
        </div>
      </MainContent>

      {/* Footer */}
      <MainContent>
        <div className="Landing-footer">
          <p className="page-text">© 2024 themrsung.com | All rights reserved.</p>
        </div>
      </MainContent>
    </Main>
  );
}

function SocialIcon({ href, icon, alt = "Social Link Icon" }: { href: string; icon: string; alt?: string }) {
  return (
    <a className="Landing-social-icon" href={href} target="_blank" rel="noreferrer">
      <img src={icon} alt={alt}></img>
    </a>
  );
}

function SocialClickIcon({ onClick, icon, alt = "Social Click Icon" }: { onClick: () => void; icon: string; alt?: string }) {
  return (
    <a className="Landing-social-icon" onClick={onClick} target="_blank" rel="noreferrer">
      <img src={icon} alt={alt}></img>
    </a>
  );
}

function FeedElement({ children, onClick = () => {} }: { children?: any; onClick?: () => void }) {
  return (
    <div className="Landing-feed-element" onClick={onClick}>
      {children}
    </div>
  );
}
